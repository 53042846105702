import { createLocalizedPathnamesNavigation } from "next-intl/navigation";
import en from "messages/en/marketing.json";
import de from "messages/de/marketing.json";
export const locales = ["en", "de"] as const;
export const localePrefix = "as-needed";

export const pathnames = {
  "/": "/",
  "/balance": "/balance",
  "/bonus": "/bonus",
  "/bonus/affiliate": "/bonus/affiliate",
  "/bonus/affiliate/details": "/bonus/affiliate/details",
  "/bonus/quest/[id]": "/bonus/quest/[id]",
  "/cashback": "/cashback",
  "/cashback/[id]": "/cashback/[id]",
  "/confirmation": "/confirmation",
  "/imprint": {
    en: `/${en.Router.imprint.toLowerCase()}`,
    de: `/${de.Router.imprint.toLowerCase()}`,
  },
  "/agb": {
    en: `/${en.Router.agb.toLowerCase()}`,
    de: `/${de.Router.agb.toLowerCase()}`,
  },
  "/privacy-policy": {
    en: `/${en.Router.privacyPolicy.toLowerCase()}`,
    de: `/${de.Router.privacyPolicy.toLowerCase()}`,
  },
  "/login": "/login",
  "/multitask/[id]": "/multitask/[id]",
  "/progress": "/progress",
  "/overview": "/overview",
  "/password/edit": "/password/edit",
  "/profile": "/profile",
  "/refer": "/refer",
  "/register": "/register",
  "/survey": "/survey",
  "/task/[id]": "/task/[id]",
  "/test": "/test",
  "/paid-surveys": {
    en: `/${en.Router.withSurveys.toLowerCase()}`,
    de: `/${de.Router.withSurveys.toLowerCase()}`,
  },
  "/how-does-rewards-work": {
    en: `/${en.Router.howDoesRewardsWork.toLowerCase()}`,
    de: `/${de.Router.howDoesRewardsWork.toLowerCase()}`,
  },
  "/games": {
    en: `/${en.Router.withGames.toLowerCase()}`,
    de: `/${de.Router.withGames.toLowerCase()}`,
  },
  "/with-cashback": {
    en: `/${en.Router.withCashback.toLowerCase()}`,
    de: `/${de.Router.withCashback.toLowerCase()}`,
  },
  "/why-rewards": {
    en: `/${en.Router.whyRewards.toLowerCase()}`,
    de: `/${de.Router.whyRewards.toLowerCase()}`,
  },
  "/blog": "/blog",
  "/blog/[postUID]": "/blog/[postUID]",
  "/blog/category/[uid]": {
    en: `/blog/${en.Router.blogCategory.toLowerCase()}/[uid]`,
    de: `/blog/${de.Router.blogCategory.toLowerCase()}/[uid]`,
  },
  "/blog/author/[uid]": {
    en: `/blog/${en.Router.blogAuthor.toLowerCase()}/[uid]`,
    de: `/blog/${de.Router.blogAuthor.toLowerCase()}/[uid]`,
  },
};

export const { Link, redirect, usePathname, useRouter }: any =
  createLocalizedPathnamesNavigation({
    locales,
    localePrefix,
    pathnames,
  });
